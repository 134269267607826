const DocMenuConfig = [
  {
    pages: [
      {
        heading: "My Homepage",
        route: "/homePage",
        fontIcon: "fa-house-user",
      },
      {
        heading: "Service Management",
        route: "/serviceManagement/",
        fontIcon: "fa-folder-minus",
      },
      {
        heading: "Claim Management",
        route: "/claimManagement",
        fontIcon: "fa-tasks",
      },
      {
        heading: "Payment Management",
        route: "/payments/",
        fontIcon: "fa-hand-holding-usd",
      },
      {
        heading: "Patient Accounts",
        route: "/patientAccounts",
        fontIcon: "fa-hospital-user",
      },
      {
        heading: "Statements",
        route: "/statements",
        fontIcon: "fa-receipt",
      },
      {
        heading: "Settings",
        route: "/settings",
        fontIcon: "fa-cog",
      },
    ],
  },
];

export default DocMenuConfig;
